import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import {styleCore} from './stylecore'

export default function TitleAndImageCollage(props) {
  const {
    title,
    subtitle,
    image_1,
    image_2,
    intro,
    tagline,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={`${className} title-and-image-collage-section stack full-width custom-max-width`}
      name="titleandimagecollage"
      section_background={section_background}
    >
      <div className="section-container title-and-image-collage-section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2>
              <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
              <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
            </h2>
          </div>
          <p className="description section-richtext section-description paragraph"><RichtextWithVariables richtext={intro}
                                                                                                           cms={cms}
                                                                                                           name={'intro'}/></p>
          <div className="row">
            <div className="columns">
              <div className="title-wrapper">
                <p className="h3 title heading-3"><RichtextWithVariables richtext={tagline} cms={cms} name={'tagline'}/></p>
              </div>
              <a href={image_1?.url} className="image left-image">
                <SectionImage
                  name="image_1"
                  src={image_1?.src ?? 'https://tools--dev.cms.eiidev.com/placeholder/480/480'}
                  mobile={image_1?.mobile}
                  alt={image_1?.alt}
                  priority={image_1?.priority}
                  sizes={image_1?.sizes}
                  width={image_1?.width}
                  height={image_1?.height}
                  cms={cms}
                />
              </a>
              <a href={image_2?.url} className="image right-image">
                <SectionImage
                  name="image_2"
                  src={image_2?.src ?? 'https://tools--dev.cms.eiidev.com/placeholder/600/400'}
                  mobile={image_2?.mobile}
                  alt={image_2?.alt}
                  priority={image_2?.priority}
                  sizes={image_2?.sizes}
                  width={image_2?.width}
                  height={image_2?.height}
                  cms={cms}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

TitleAndImageCollage.StyleCore = styleCore
