import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {styleCore} from './stylecore'


export default function QuoteImageLinks(props) {
  const {
    title,
    subtitle,
    intro,
    quote,
    cite,
    image_1,
    image_2,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' quote-image-links-section stack'}
      name="quoteimagelinks"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content quote_image_links_gutter_row_1 quote_image_links_gutter_column_1">
          <div className="section-heading">
            <h2 className="heading-1">
                    <span className="title">
                        <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
                    </span>
              <span className="subtitle heading-2">
                        <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
                    </span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row quote-image-links-row">
            <div className="column quote">
              <blockquote className="quote">
                <span>"<RichtextWithVariables richtext={quote} cms={cms} name={'quote'}/>"</span>
                <cite className="citation"><InlineTextWithVariables cms={cms} value={cite} name="cite"/></cite>
              </blockquote>
            </div>
            <div className="column shrink">
              <a className="quote-image-links-image-wrapper" href="/" style={{
                  backgroundImage: `url('${image_1?.src}')`
              }}>
                <div className="quote-image-links-image-title-wrapper">
                    <span className="button">
                        Title
                    </span>
                </div>
              </a>
            </div>
            <div className="column shrink">
              <a className="quote-image-links-image-wrapper" href="/"
                 style={{
                     backgroundImage: `url('${image_2?.src}')`
                 }}>
                <div className="quote-image-links-image-title-wrapper">
                  <span className="button">
                    Title
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

QuoteImageLinks.StyleCore = styleCore

