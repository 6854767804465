import type {Field} from '@einsteinindustries/tinacms'
import type {
  BlocksControlsProps
} from '@einsteinindustries/react-tinacms-inline/dist/src/blocks/inline-block-field-controls'
import type {FocusRingOptions} from '@einsteinindustries/react-tinacms-inline/dist/src/styles'
import React, {useEffect, useState} from 'react'
import type {ComponentLoader} from '@/components/shared/types'

type LucidInlineGroupsProps = {
  cms: boolean
  name: string
  fields?: Field[]
  insetControls?: boolean
  focusRing?: boolean | FocusRingOptions
  children?: any
}

type LucidBlocksControlsProps = {
  cms: boolean,
  className?: string
} & BlocksControlsProps

export function LucidInlineGroups({cms, children, ...props} : LucidInlineGroupsProps) {

  const [tinaComponent, setTinaComponent] = useState<ComponentLoader<any>>({
    Component: null
  })

  useEffect(() => {
    async function loadTina() {
      const {InlineGroup} = await import('@einsteinindustries/react-tinacms-inline')
      setTinaComponent({Component: InlineGroup})
    }
    if (cms) {
      loadTina()
    }
  }, [])

  if (tinaComponent.Component) {
    return <tinaComponent.Component {...props}>{children}</tinaComponent.Component>
  }
  return <div>{children}</div>
}

export function LucidBlocksControls({cms, ...props}: LucidBlocksControlsProps) {

  const [tinaComponent, setTinaComponent] = useState<ComponentLoader<BlocksControlsProps>>({
    Component: null
  })
  useEffect(() => {
    async function loadTina() {
      const {BlocksControls} = await import('@einsteinindustries/react-tinacms-inline')
      setTinaComponent({Component: BlocksControls})
    }

    if (cms) {
      loadTina()
    }
  }, [])

  if (tinaComponent.Component) {
    return (
      <tinaComponent.Component {...props}>
        <div style={{minWidth: '60px', minHeight: '60px'}} className={props.className ?? ''}>{props.children}</div>
      </tinaComponent.Component>
    )
  }

  return <div className={props.className ?? ''}>{props.children}</div>
}
