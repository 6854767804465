import NextImage from 'next/image'

interface StaticMapProps {
  address?: string
  center?: {lat: number; long: number}
  zoom: number // 5-15
  size: 'small' | 'large'
}

const StaticMap = ({address, center, zoom, size}: StaticMapProps) => {
  const width = size === 'large' ? 540 : 250
  const height = size === 'large' ? 540 : 150

  const centerParam = center
    ? `${center.lat},${center.long}`
    : `${encodeURIComponent(address ?? '')}`

  const mapImageUrl = `/api/static-map?center=${centerParam}&zoom=${zoom}&width=${width}&height=${height}`
  
  return (
    <NextImage
      src={mapImageUrl}
      alt="Location Map"
      width={width}
      height={height}
      unoptimized={true}
    />
  )
}

export default StaticMap
