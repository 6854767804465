import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  timeline: {
    ...baseItem,
    label: 'Timeline Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/130/130',
        alt: '',
      },
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              return constructAssetUrl(media.id)
            },
            previewSrc: (src) => {
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
    ],
  },
}

export const TimelineItem = ({data, cms}) =>
    <div className="column timeline-item has-icon">
      <div className="timeline-item-container animate-item">
        <div className="timeline-item-content">
          <h3 className="timeline-item-title heading-4"><RichtextWithVariables richtext={data.title} cms={cms} name={'data.title'}/></h3>
          <div className="timeline-item-description paragraph">
            <RichtextWithVariables richtext={data.description} cms={cms} name={'data.description'}/>
          </div>
        </div>
        <div className="timeline-item-image animate-item">
          <img src={data.image.src} alt={data.image.alt} />
        </div>
      </div>
    </div>

export const TIMELINE_ITEM_BLOCKS = {
  timeline: {
    Component({_name, index, data, cms}) {
      return (
        <ItemWrapper index={index} cms={cms}>
            <TimelineItem data={data} cms={cms} />
        </ItemWrapper>
      )
    },
    template: itemTemplates.timeline,
  },
}

function ItemWrapper(props) {
  if (!props.cms) {
    return props.children
  }
  return (
    <LucidBlocksControls
      index={props.index}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={true}
      cms={props.cms}>
      {props.children}
    </LucidBlocksControls>
  )
}

