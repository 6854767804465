import styled from 'styled-components'
import {BUTTON_FIELDS} from '@/components/sections/shared/InputTypesFields'
import {InlineTextWithVariables} from '@/components/sections/shared/SectionsWithVariables'
import React from 'react'
import {LucidInlineGroups} from '@/components/sections/shared/LucidInlineGroups'

export interface SectionButtonProps {
  label: string
  href: string
  cms: boolean
  name: string
  openInNewTab?: boolean
}

const StyledDiv = styled.div`
  display: grid;
`

const StyledSpan = styled.span`
  display: inline-block;
  min-width: 320px;
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
  justify-self: center;

  @media (max-width: 1024px) {
    min-width: 30vw;
  }

  @media (max-width: 640px) {
    min-width: unset;
    width: 100%;
  }
`

function SectionButton(
  {
    label,
    href,
    cms,
    name,
    openInNewTab = false
  }: SectionButtonProps) {
  return (
    <LucidInlineGroups
      cms={cms}
      name={name}
      insetControls={true}
      fields={BUTTON_FIELDS}
    >
      <Button cms={cms} label={label} href={href} openInNewTab={openInNewTab} />
    </LucidInlineGroups>
  )
}

export function Button({href, label, cms, openInNewTab = false}: { href: string, label: string, cms: boolean, openInNewTab: boolean }) {
  function noLinkOnCms(e: React.MouseEvent<HTMLElement>) {
    if (cms) {
      e.preventDefault()
    }
  }

  const hasData = href && label
  if (hasData) {
    return (
      <a
        href={href}
        onClick={noLinkOnCms}
        className="section-button button button-border-0-1"
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noopener noreferrer' : ''}
      >
        <span className="button-border-0-2"></span>
        <span className="button-text">
          <InlineTextWithVariables value={label} cms={cms} name={'label'}/>
        </span>
      </a>
    )
  }
  return null
}

export default SectionButton
