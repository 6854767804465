import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {InlineTextWithVariables} from '../shared/SectionsWithVariables'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

// TODO: Use actual `cms`

const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

const itemTemplates = {
  block_portrait: {
    ...baseItem,
    label: 'Block Portrait Item',
    defaultItem: {
      ...baseItem.defaultItem,
      title: 'Title',
      image: {
        src: '',
        alt: '',
      },
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
            description: 'Image description',
            clearable: true,
            parse: (media) => {
              if (!media) {
                return
              }
              return constructAssetUrl(media.id)
            },
            previewSrc: (src) => {
              return src
            },
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
          },
        ],
      },
    ],
  },
}

export const MENU_ITEM_BLOCKS = {
  block_portrait: {
    Component({_name, index, data, cms}) {
      return (
        <>
          <div className="menu-block-portrait-item">
            <ItemWrapper index={index} cms={cms}>
              <div className="menu-block-portrait-item-aspect-ratio">
                <div className="menu-block-portrait-item-container menu-block-portrait-item-label">
                              <span>
                                  <span className="menu-item-title menu-block-item-title menu-block-portrait-item-title heading-3">
                                    <InlineTextWithVariables value={data.title} name="data.title" cms={cms}/>
                                  </span>
                                  <span className="menu-item-subtitle menu-block-item-subtitle menu-block-portrait-item-title heading-4">
                                    <InlineTextWithVariables value={data.subtitle} name="data.subtitle" cms={cms}/>
                                  </span>
                              </span>
                </div>
                <div className="menu-block-portrait-item-image" style={{
                  backgroundPosition: '50% 50%',
                  backgroundImage: `url(${data.image.src})`,
                }}></div>
                <div className="dimmer" style={{
                  opacity: 0.8
                }}></div>
                <div className="menu-card-item-border-0-2"></div>
              </div>
            </ItemWrapper>
          </div>
          <div className="menu-item-break"></div>
        </>
      )
    },
    template: itemTemplates.block_portrait,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      cms={props.cms}
      index={props.index}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </LucidBlocksControls>
  )
}

