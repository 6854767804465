import Section from '../shared/Section'
import {getMenuContentTemplates, getSectionTemplate} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {useEffect, useState} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {styleCore} from './stylecore'
import useWindowDimensions, {WindowSizeKey} from '../shared/useWindowWidthListener'
import {Mobile, Monitor} from 'iconsax-react'
import {Button} from '@nextui-org/react'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'

const NAVIGATION_OFFSET = 61 //This is the height of the tina navbar

function StickyNavigationCmsControlsEnablement() {

  useEffect(() => {
    window.scrollTo(0, NAVIGATION_OFFSET)

    const scrollAction = () => {
      if (window.scrollY > 0 && window.scrollY < NAVIGATION_OFFSET) {
        window.scrollTo(0, NAVIGATION_OFFSET)
      }
    }

    document.addEventListener('scrollend', scrollAction)

    return () => {
      document.removeEventListener('scrollend', scrollAction)
    }
  }, [])

  return <div style={{height: NAVIGATION_OFFSET, backgroundColor: '#eaeaea'}}></div>
}

const MODES = {
  desktop: {
    label: 'Desktop',
    icon: <Monitor size={20}/>,
    size: WindowSizeKey.lg
  },
  mobile: {
    label: 'Mobile',
    icon: <Mobile size={20}/>,
    size: WindowSizeKey.sm
  }
}

function ContentPreviewToggle({previewMode, setPreviewMode}) {
  function onClickHandler() {
    setPreviewMode(() => previewMode === 'desktop' ? 'mobile' : 'desktop')
  }

  const buttonContent = <>{MODES[previewMode].icon} {MODES[previewMode].label}</>

  return <Button onPress={onClickHandler} size="sm" style={{minWidth: 10}}>{buttonContent}</Button>
}

export default function Navigation(props) {
  const {
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    cms,
    meta
  } = props

  const [showMenu, setShowMenu] = useState(false)
  const [previewMode, setPreviewMode] = useState('desktop')
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const scrollAction = () => {
      if (window.scrollY > 0) {
        setScrolled(meta?.style?.config?.navigationBar?.scrolling?.shrinking?.enabled ?? false)
      } else {
        setScrolled(false)
      }
    }

    document.addEventListener('scroll', scrollAction)

    return () => {
      document.removeEventListener('scroll', scrollAction)
    }
  })

  const {size} = useWindowDimensions()

  function getSwitchedState() {
    const activeSize = cms ? MODES[previewMode].size : size
    const sizeName = Object.keys(MODES).find((key) => MODES[key].size <= activeSize)
    if (typeof sizeName === 'undefined') {
      return Object.keys(MODES).sort((a, b) => MODES[a].size - MODES[b].size)[0]
    } else {
      return sizeName
    }
  }

  function getNavigationScrolledClasses() {
    let gradientClass = ''
    let shrinkClass = ''
    let colorWashClass = ''
    if (meta?.style?.config?.navigationBar?.scrolling?.gradient?.colorWash) {
      colorWashClass = ' navigation-inner-color-wash'
    }
    if (scrolled && !(getSwitchedState() === 'mobile' && !meta?.style?.config?.navigationBar?.scrolling?.shrinking?.shrinkOnMobile)) {
      shrinkClass = 'navigation-inner-shrunk'
    }
    if (!showMenu && ((scrolled && ['scrolled', 'always'].includes(meta?.style?.config?.navigationBar?.scrolling?.gradient?.mode)) ||
      (!scrolled && ['top', 'always'].includes(meta?.style?.config?.navigationBar?.scrolling?.gradient?.mode)))) {
      gradientClass = 'navigation-inner-gradient' + colorWashClass
    }
    return `${shrinkClass} ${gradientClass}`
  }

  useEffect(() => {
    showMenu ? disableBodyScroll(document) : enableBodyScroll(document)
  }, [showMenu])

  const navigationItemName = `navigation_item_size_${getSwitchedState()}`
  const menuItemName = `navigation_menu_item_size_${getSwitchedState()}`
  const navigationClass = `navigation-${getSwitchedState()}`
  return (
    <>
      {cms && <StickyNavigationCmsControlsEnablement/>}
      <LucidBlocksControls
        index={0}
        focusRing={{offset: -15}}
        label={false}
        customActions={[
          {
            icon: <ContentPreviewToggle previewMode={previewMode} setPreviewMode={setPreviewMode}/>,
            onClick: () => {
            }
          }
        ]}
        cms={cms}  
      >
        <Section
          cms={cms}
          className={className}
          name="navigation"
          css_overrides={css_overrides}
          color_scheme_id_override={color_scheme_id_override}
          meta={meta}
          page_section_id={page_section_id}
          section_background={section_background}
        >
          <div className={`navigation-inner ${getNavigationScrolledClasses()}`}>
            <AnimatePresence initial={false}>
              <LucidInlineBlocks
                className={`flex-parent ${navigationClass}`}
                name={navigationItemName}
                blocks={{
                  section: getSectionTemplate({
                    changeShowMenu: () => {
                      setShowMenu(!showMenu)
                    },
                    showMenu: showMenu
                  }, (props[navigationItemName] ?? []).length)
                }}
                direction="horizontal"
                itemProps={props}
              />
            </AnimatePresence>
          </div>
          <AnimatePresence>
            {showMenu && (
              <motion.div
                key="a box"
                initial={{opacity: 0, y: -50}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: -50}}
                transition={{duration: 0.5, ease: [0.10, 0.13, 0.10, 0.96], delay: 0.1}}
                className={'navigation-menu colorscheme-background-color'}
              >
                <LucidInlineBlocks
                  itemProps={props}
                  className={`navigation-menu-container ${navigationClass}`}
                  name={menuItemName}
                  blocks={{
                    ...getMenuContentTemplates({
                      changeShowMenu: () => {
                        setShowMenu(!showMenu)
                      },
                      showMenu: showMenu
                    }, cms)
                  }}
                  direction={'horizontal'}
                />
              </motion.div>)
            }
            {
              showMenu && (
                <div className={'navigation-menu-shadow-wrapper'}>
                  <motion.div
                    key="shadowBox"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.5, ease: [0.10, 0.13, 0.10, 0.96]}}
                    onClick={() => {
                      setShowMenu(false)
                    }}
                    className={'navigation-menu-shadow'}
                  >
                    <div/>
                  </motion.div>
                </div>
              )
            }
          </AnimatePresence>
        </Section>
      </LucidBlocksControls>
    </>
  )
}

Navigation.StyleCore = styleCore
