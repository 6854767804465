import {PresetList} from '@/components/shared/types'

export const presets: PresetList = [
  {
    display: {
      name: 'Reviews: Increased Padding',
      documentationURL: 'https://www.notion.so/einsteinindustries/Reviews-Increased-Padding-38bbe9d68037418ba34da77764999da9?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/reviews-IID-54.gif'
    },
    allowedSections: [],
    notion_IID: '54',
    css: `
    /* Reviews: Increased Padding */
.reviews-item {
    padding: 3rem;
}
@media (max-width:1024px) {
    .reviews-item {
        padding: 1.5rem;
    }
}
    `
  },
  {
    display: {
      name: 'Review 2 column',
      documentationURL: 'https://www.notion.so/einsteinindustries/Review-2-column-e4431165c8ef444393362407d085ac9c?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/reviews-IID-122.png'
    },
    allowedSections: [],
    notion_IID: '122',
    css: `
    @media (min-width:1024px) {
section.color-scheme-15::after {
  clear: both;
  content: '';
  display: block;
}


section.color-scheme-15 .reviews-wrapper {
float: right;
}



section.color-scheme-15 .reviews-item  {
  flex-basis: 100%;
  width: 100%;
  margin: 0;
}

section.color-scheme-15 .row.reviews-wrapper {
  display: inline-block;
  max-width: 50%;

}

section.color-scheme-15 .section-button {
display: inline-block;
max-width: initial;
width: 50%;
text-align: center;
padding-right: 2rem;
float: left;
}


section.color-scheme-15 .section-outro + .section-button {

  float: right;

}




section.color-scheme-15 .section-outro {
max-width: 49%;
float: right;
}


section.color-scheme-15 .section-description {
max-width: 49%;
float: left;
}





section.color-scheme-15 .section-outro .column  {
  flex-basis: 100%;
}

section.color-scheme-15 .section-description .column  {
  flex-basis: 100%;
}
}
    `
  },
  {
    display: {
      name: 'Reviews : Front stack margin fixes',
      documentationURL: 'https://www.notion.so/einsteinindustries/Reviews-Front-stack-margin-fixes-228103da713243459f5e31886df014e3?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/reviews-IID-113.gif'
    },
    allowedSections: [],
    notion_IID: '113',
    css: `
    /* Review Section: Fixing front card margins */
section.reviews-section.front-stack .section-content {
    margin-bottom: -3rem;
}

@media only screen and (min-width: 641px) {
section.front-stack.reviews-section .section-content {
\t\tmargin-left: 1rem ;
    margin-right: 1rem ;

}
}
    `
  },
  {
    display: {
      name: 'Reviews: Circle Profile on Top',
      documentationURL: 'https://www.notion.so/einsteinindustries/Reviews-Circle-Profile-on-Top-4e2e0c7fe7c247a18ae6fa15090f3660?pvs=4',
      image: 'https://einstein-app-dev-lucid-assets.imgix.net/preset-previews/reviews-IID-100.png'
    },
    allowedSections: [],
    notion_IID: '100',
    css: `
    /* Reviews: Circle Profile on Top ------------------- */
.reviews-section .reviews-item { 
    padding: 2rem 3rem; 
    padding-top: 3rem;
}
@media (max-width:1024px) {
    .reviews-section  .reviews-item { padding: 1.5rem; }
}
.reviews-section .row.reviews-wrapper {
    margin-top: 4rem !important;
}
.reviews-section a.reviews-item__avatar-wrapper {
    display: flex;
    position: absolute;
    top: -6rem;
    left: calc(50% - 2.5rem);
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
}
.reviews-section a.reviews-item__avatar-wrapper img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 1000px;
    padding: 0.35rem;
    background: #fff;
    box-shadow: 0rem 0rem 0.5rem #0005;
}
@media (max-width: 850px) {
.reviews-section .reviews-item { 
    margin: 2rem 0 4.5rem 0;
}
}
    `
  }
]
