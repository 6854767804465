import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {MENU_ICON_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'

export default function MenuIcon(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section back-stack menu-icon-section menu-section'}
      name="menuicon"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading" style={{textAlign: 'center'}}>
            <h2>
              <span className="title color-scheme-title heading-1">
                <InlineTextWithVariables cms={cms} name="title" value={title}/>
              </span>
              <span className="subtitle color-scheme-subtitle heading-2">
                <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
              </span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables
                    name="intro"
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <LucidInlineBlocks
            itemProps={props}
            name="menu_icon_items"
            blocks={MENU_ICON_ITEM_BLOCKS}
            className={`row menus menu-icon-items item-count-${props?.menu_icon_items?.length ?? 0}`}
          />
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables
                  name="outro"
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <SectionButton name='button' href={button?.url} label={button?.label} cms={cms}/>
          </div>
        </div>
      </div>
    </Section>
  )
}

MenuIcon.StyleCore = styleCore
