import SectionContactForm from '../shared/SectionContactForm'
import Section from '../shared/Section'
import SectionText from '../shared/SectionText'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {styleCore} from './stylecore'


export default function ContactForm(props) {
  const {
    title,
    subtitle,
    richtext,
    quote,
    author,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      className={className + ' site section stack contactform-section contact-form-unstack contact-form-mobile-unstack'}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      name="contactform"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading flexbox-alignment middle mobile-middle has-quote">
            <h2>
              <span className="title heading-1">
                <SectionText name='title' text={title} cms={cms}/>
              </span>
              <span className="subtitle heading-2">
                <SectionText name='subtitle' text={subtitle} cms={cms}/>
              </span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables
                    name='richtext'
                    richtext={richtext}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section-form">
            <div className="row flexbox-alignment middle mobile-middle has-quote">
              <div className="column">
                <SectionContactForm {...props}/>
              </div>
              {quote && author &&
                <div className="column">
                  <blockquote className="quote">
                    <span>
                      <SectionText name='quote' text={quote} cms={cms}/>
                    </span>
                    <cite>
                      <SectionText name='author' text={author} cms={cms}/>
                    </cite>
                  </blockquote>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

ContactForm.StyleCore = styleCore
