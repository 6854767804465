import SectionText from '../shared/SectionText'
import {SOCIAL_STRIPE_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import StyledSection from '../shared/Section'
import {styleCore} from './stylecore'

export default function SocialStripe(props) {
  const {
    title,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    cms,
    meta
  } = props
  return (
    <StyledSection
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' site section stack socialstripe-section'}
      name="socialstripe"
      section_background={section_background}  
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-widget">
            <div className="heading-1">
              <SectionText name="title" text={title} cms={cms}/>
            </div>
            <div className="accounts-and-link">
              <LucidInlineBlocks
                itemProps={props}
                name="socialstripe_items"
                blocks={SOCIAL_STRIPE_ITEM_BLOCKS}
                className="section-item-wrapper"
                direction="horizontal"
              />
            </div>
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

SocialStripe.StyleCore = styleCore
