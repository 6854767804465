import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {styleCore} from './stylecore'
import {LucidBlocksControls} from '@/components/sections/shared/LucidInlineGroups'

const itemTemplates = {
  heroprefacedescription: {
    label: 'Item',
    defaultItem: {
      description: 'description',
    },
    itemProps: (item) => {
      return {description: item.description}
    },
    // TODO: Create a set of default fields
    fields: [
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      }
    ],
  }
}

function ItemWrapper(props) {
  if (!props.cms) return props.children
  return (
      <LucidBlocksControls
          cms={props.cms}
          index={props.index}
          focusRing={{offset: 16, borderRadius: 4}}
          insetControls={false}
      >
        {props.children}
      </LucidBlocksControls>
  )
}

const DESCRIPTION_ITEM_BLOCKS = {
  heroprefacedescription: {
    Component({_name, index, data, cms}) {
      return (
          <ItemWrapper index={index} cms={cms}>
            <p className={'paragraph'}><RichtextWithVariables richtext={data.description} cms={cms} name={'description'}/></p>
          </ItemWrapper>
      )
    },
    template: itemTemplates.heroprefacedescription,
  },
}

export default function HeroPreface(props) {
  const {
    title,
    subtitle,
    image,
    image_mobile,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section hero-preface-section stack'}
      name="heropreface"
      heroImage={image?.src}
      section_background={section_background}
    >
        <div className="section-container">
          <div className="section-content">
            <div className="hero-preface-mobile-image-wrapper show-for-mobile">
              <SectionImage
                name={'image_mobile'}
                src={image_mobile?.src}
                mobile={image_mobile?.mobile}
                alt={image_mobile?.alt}
                cms={cms}
                width={image_mobile?.width}
                height={image_mobile?.height}
                priority={image_mobile?.priority}
                sizes={image_mobile?.sizes}
              />
            </div>
            <div className="hero-preface-content">
              <div>
                <div className="hero-preface-tagline">
                  <div className="section-heading">
                    <h1>
                      <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
                      <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
                    </h1>
                  </div>
                </div>
                <LucidInlineBlocks
                    itemProps={props}
                    name="descriptions"
                    blocks={DESCRIPTION_ITEM_BLOCKS}
                />
              </div>
            </div>
          </div>
        </div>
        <span className="hero-preface-image show-for-desktop lazyloaded">
          <SectionImage
            name={'image'}
            className={'hero-preface-image show-for-desktop ls-is-cached lazyloaded'}
            src={image?.src ?? ''}
            mobile={image?.mobile}
            alt={image?.alt}
            cms={cms}
            width={image?.width}
            height={image?.height}
            priority={image?.priority}
            sizes={image?.sizes}
          />
        </span>
    </Section>
  )
}

HeroPreface.StyleCore = styleCore

